import { useContext } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Button";
import { useTranslation } from "react-i18next";

import LanguageIcon from "@mui/icons-material/Language";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import AddCardRoundedIcon from "@mui/icons-material/AddCardRounded";

import "./Recipe.css";
import config from "../config/config";
import { ServiceLayerContext } from "shareapps-react-ui";
import { useNavigate } from "react-router-dom";

function AddRecipe(props) {
  let { t } = useTranslation();
  const navigate = useNavigate();
  const serviceLayer = useContext(ServiceLayerContext);

  const urlScan = function () {
    navigate("/urlScan");
  };

  const pictureScan = function () {
    navigate("/imageScan");
  };

  const addRecipe = function () {
    let recipe = {
      title: t("NewRecipe"),
      pics: [
        {
          url: config.genericRecipeUrl,
          status: "externalUrl",
          text: "generic recipe picture",
        },
      ],
      intro: "",
      originURL: "",
      prepSteps: [],
      tags: [],
      ingGroups: [],
    };

    const getData = async function () {
      let recipeId = await serviceLayer.recipeServices.storeRecipe(recipe);
      navigate("/editRecipe/" + recipeId);
    };
    getData().catch((err) => {
      props.addInfo(err);
    });
  };

  var pictureScanButton = null;
  if (serviceLayer.featureToggle("recipes-image-scan")) {
    pictureScanButton = (
      <Button
        className="recipes-button"
        color="inherit"
        size="small"
        startIcon={<AddAPhotoIcon />}
        onClick={pictureScan}
      >
        {t("RecipePictureScan")}
      </Button>
    );
  }

  return (
    <div width="300px">
      <Button
        className="recipes-button"
        size="small"
        color="inherit"
        startIcon={<LanguageIcon />}
        onClick={urlScan}
      >
        {t("RecipeUrlScan")}
      </Button>
      {pictureScanButton}
      <Button
        className="recipes-button"
        size="small"
        color="inherit"
        startIcon={<PlaylistAddRoundedIcon />}
        onClick={addRecipe}
      >
        {t("RecipeTypeText")}
      </Button>
      <Divider
        component="div"
        role="presentation"
        color="warning"
        style={{ width: "100%" }}
      />
      <Button
        className="recipes-button"
        size="small"
        color="inherit"
        startIcon={<AddCardRoundedIcon />}
      >
        {t("AddChapterText")}
      </Button>
    </div>
  );
}

export default AddRecipe;
